import React from 'react';
import Form from 'react-bootstrap/Form'; //for toggle
// import Nav from 'react-bootstrap/Nav';
import { useSelector, useDispatch } from 'react-redux';
// import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';
import { appLanguages } from '../../constants/appConstants';

import { changeLanguage, generalReducer } from '../../redux/generalSlice';
// import { appRoutes } from '../../constants/appRoutes';
// import PermissionWrapper from '../pattern/PermissionWrapper.js';
import { getUserNavItems } from './layoutUtils';
import { authInfo } from '../../pages/auth/authSlice';
import MenuComponent from './MenuComponent';

const Leftbar = ({ showMenu }) => {
  // const ZONE_ID = process.env.REACT_APP_DASHBOARD_ISAS_CAT_LIST_ZONE_ID || 188;
  const { appLanguage } = useSelector(generalReducer);
  const {
    user: { activeJurisdiction },
  } = useSelector(authInfo);

  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const handleSwitchChange = () => {
    if (appLanguage === appLanguages.Bengali) {
      dispatch(changeLanguage(appLanguages.English));
      return;
    }
    dispatch(changeLanguage(appLanguages.Bengali));
  };

  return (
    <>
      <div className={showMenu ? 'leftbar-wrapper leftbar-show ' : 'leftbar-wrapper  leftbar-hide'}>
        <div className='width-leftbar d-flex flex-column '>
          <div className='d-flex flex-column px-2 px-lg-3 gap-3 py-3 h-100'>
            <MenuComponent menuItems={getUserNavItems(activeJurisdiction.permissions)} />
          </div>
        </div>
      </div>

      <div
        className={
          showMenu
            ? 'language-toggle-container language-toggle-container-show '
            : 'language-toggle-container language-toggle-container-hide'
        }
      >
        <div className='d-flex flex-column '>
          <Form className='d-flex  justify-content-center  align-items-center fs-6  border-top px-4 px-lg-5'>
            <label
              className='language-toggle-text mt-2 text-decoration-none'
              htmlFor='languageChangeSwitch'
            >
              English
            </label>
            <Form.Check
              type='switch'
              checked={appLanguage === appLanguages.Bengali}
              className='mt-2 ms-2'
              onChange={handleSwitchChange}
              id='languageChangeSwitch'
            />
            <label
              className='language-toggle-text mt-2 text-decoration-none'
              htmlFor='languageChangeSwitch'
            >
              বাংলা
            </label>
          </Form>
          <span className='p-1 text-center fw-light'>
            <span className='text-small'>
              All rights reserved by directorate of secondary and higher education{' '}
            </span>
          </span>
        </div>
      </div>
    </>
  );
};

export default Leftbar;
