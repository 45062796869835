import { getLocationParams } from '../utils/helperFunction';

const dateDefaultValue = { fromDate: '', toDate: '' };
const locationDefaultValue = {
  zoneId: '',
  districtId: '',
  upazilaId: '',
};

export const apiRoutes = {
  ssoLogin: '/sso/login',
  getRefreshToken: '/sso/refresh',
  getZones: '/api/location/zones',
  getAllLocations: '/api/location/all',
  addHolidays: '/api/holiday/add-holidays',
  sendForRevision: '/api/visit-calendar/reject-plan',
  sendReminder: '/api/visit-calendar/reminder/notify',
  initiateNewPlan: '/api/visit-calendar/session-plan',
  getAllSession: '/api/visit-calendar/session-plan/all',
  getSessionLastYear: '/api/visit-calendar/new-session-year',
  getOngoingSessionPlan: '/api/visit-calendar/ongoing-session',
  getOfficerInstitute: '/api/educational-institute/officer/institute/count',

  deleteHoliday: (id) => `/api/holiday/${id}/delete`,
  getHolidays: (year) => `/api/holiday/all?year=${year}`,
  addNewPlan: (sessionId) => `/api/visit-calendar/plan/${sessionId}`,
  getPlanSessionById: (id) => `/api/visit-calendar/session-plan/${id}`,
  deleteVisitPlanDetail: (id) => `/api/visit-calendar/plan-detail/${id}`,
  updateSessionPlan: (id) => `/api/visit-calendar/update/session-plan/${id}`,
  submitVisitPlan: (officerId, planYear) =>
    `/api/visit-calendar/submit/visit-plan/officer/${officerId}/year/${planYear}`,
  getSessionByYear: (year) => `/api/visit-calendar/session?planYear=${year}`,
  getSessionById: (sessionId) => `/api/visit-calendar/session-plan/${sessionId}`,
  getHolidaysBySessionId: (id) => `/api/visit-calendar/session-plan/${id}/holiday`,
  getToken: (username, token) => `/sso/start-session?username=${username}&token=${token}`,
  getTokenDev: (username, token) => `/sso/start-session-dev?username=${username}&token=${token}`,
  getOfficerPlanCalendarId: (officerId, planYear) =>
    `/api/visit-calendar/officer/${officerId}/plan-calendar/planYear/${planYear}`,
  getAllPlanByPlanIdAndDate: (officerId, planId, date) =>
    `/api/visit-calendar/plan-details/officer/${officerId}?planSessionId=${planId}&visitDate=${date}`,
  getAInstituteAllPlan: (sessionId, instituteId) =>
    `/api/visit-calendar/plan/session/${sessionId}/institute/${instituteId}/all`,
  getIntitutionsByPathParams: (path, params) =>
    `api/educational-institute/get-by-path-params?path=${path}&startWith=${params}`,
  getOfficerStat: (
    officerId = '',
    planSessionId = '',
    planYear = '',
    visitDate = '',
    fromDate = '',
    toDate = '',
    instituteId = '',
  ) =>
    `/api/visit-calendar/plan/stat/${officerId}?planSessionId=${planSessionId}&planYear=${planYear}&visitDate=${visitDate}&fromVisitDate=${fromDate}&toVisitDate=${toDate}&instituteId=${instituteId}`,

  getOfficerAllPlan: (
    officerId = '',
    planSessionId = '',
    planYear = '',
    visitDate = '',
    fromDate = '',
    toDate = '',
    instituteId = '',
  ) =>
    `/api/visit-calendar/plan-details/officer/${officerId}?planSessionId=${planSessionId}&planYear=${planYear}&visitDate=${visitDate}&fromVisitDate=${fromDate}&toVisitDate=${toDate}&instituteId=${instituteId}`,
  getInstitutePlanCalendar: (planCalendarId) =>
    `api/visit-calendar/institute-plan-calendar/${planCalendarId}`,

  getAllZoneStatByYear: (year) => `/api/visit-calendar/plan-submission/zones/stat/${year}`,
  getZoneStat: (
    year,
    zoneId = '',
    districtId = '',
    upazilaId = '',
    designationId = '',
    status = '',
  ) =>
    `/api/visit-calendar/plan-submission/stat?zoneId=${zoneId}&districtId=${districtId}&upazilaId=${upazilaId}&designationId=${designationId}&planYear=${year}&status=${status}`,
  getPlanSubmission: (
    year,
    size,
    pageNumber,
    zoneId,
    districtId = '',
    upazilaId = '',
    status = '',
    designationId = '',
  ) =>
    `/api/visit-calendar/plan-submission?zoneId=${zoneId}&districtId=${districtId}&upazilaId=${upazilaId}&designationId=${designationId}&status=${status}&planYear=${year}&size=${size}&page=${pageNumber}`,

  getOfficerVisitPlanByYear: (planYear, officerId) =>
    `/api/visit-calendar/officer-visit-calendar/visited-status?planYear=${planYear}&officerId=${officerId}`,
  getAllDesignations: (userType) => `/api/designation/all?userTypes=${userType}`,
  getNonCompliantVisitPlan: (params) => {
    let queryParams = `zoneId=${params.zoneId}&districtId=${params.districtId}&upazilaId=${params.upazilaId}&planYear=${params.planYear}&calendarStatus=${params.calendarStatus}&isasCategory=${params.isasCategory}`;

    return `/api/visit-calendar/non-compliant-visit-plan?${queryParams}`;
  },

  isasComplaintDetails: (pageNumber, pageSize, planYear, zoneId, districtId = '') => {
    return `/api/visit-calendar/isas-complaint-details?planYear=${planYear}&zoneId=${zoneId}&districtId=${districtId}&&page=${pageNumber}&size=${pageSize}`;
  },

  getAuditLogs: (
    pagination,
    location = locationDefaultValue,
    designation = '',
    actionType = '',
    date = dateDefaultValue,
  ) => {
    return `/api/audit/action-logs?page=${pagination.pageNumber}&size=${pagination.pageSize}&zone=${location.zoneId}&district=${location.districtId}&upazila=${location.upazilaId}&designation=${designation}&actionType=${actionType}&fromDate=${date.fromDate}&toDate=${date.toDate}`;
  },

  getGeneralVisits: (
    pagination,
    location = locationDefaultValue,
    designation = '',
    date = dateDefaultValue,
    searchValue = '',
  ) => {
    return `/api/survey/general/monitoring/visits?page=${pagination.pageNumber}&size=${pagination.pageSize}&zone=${location.zoneId}&district=${location.districtId}&upazila=${location.upazilaId}&designationId=${designation}&fromDate=${date.fromDate}&toDate=${date.toDate}&searchParam=${searchValue}`;
  },

  getGeneralVisitsByParam: (searchValue = '') => {
    return `/api/survey/general/monitoring/visits?searchParam=${searchValue}`;
  },

  getCurriculumVisits: (
    pagination,
    location = locationDefaultValue,
    designation = '',
    date = dateDefaultValue,
    searchValue = '',
  ) => {
    return `/api/survey/classroom/monitoring/visits?page=${pagination.pageNumber}&size=${pagination.pageSize}&zone=${location.zoneId}&district=${location.districtId}&upazila=${location.upazilaId}&designationId=${designation}&fromDate=${date.fromDate}&toDate=${date.toDate}&searchParam=${searchValue}`;
  },

  getCurriculumVisitsByParam: (searchValue = '') => {
    return `/api/survey/classroom/monitoring/visits?searchParam=${searchValue}`;
  },

  getOfficeVisits: (
    pagination,
    location = locationDefaultValue,
    designation = '',
    date = dateDefaultValue,
    searchValue = '',
  ) => {
    return `/api/survey/office/monitoring/visits?page=${pagination.pageNumber}&size=${pagination.pageSize}&zone=${location.zoneId}&district=${location.districtId}&upazila=${location.upazilaId}&designationId=${designation}&fromDate=${date.fromDate}&toDate=${date.toDate}&searchParam=${searchValue}`;
  },

  getOfficeVisitInfoByVisitId: (visitId) => {
    return `/api/survey/office/monitoring/${visitId}`;
  },

  getMonitoringSurveyForm: (formType) => {
    return `/api/survey/form/${formType}`;
  },

  getCurriculumVisitInfoByVisitId: (visitId) => {
    return `/api/survey/classroom/monitoring/${visitId}`;
  },

  getGeneralVisitInfoByVisitId: (visitId) => {
    return `/api/survey/general/monitoring/${visitId}`;
  },
  downloadPlanSubmissionDetails: (type, location, params) =>
    `/report/officer-submitted-pending-plan/${type}?${getLocationParams(location)}&planYear=${
      params.planYear
    }&status=${params.status}&designationId=${params.designationId}`,
  downloadOfficerVisitPlan: (type, officerId, planYear) =>
    `/report/officer-yearly-visit-plan/${type}?officerId=${officerId}&planYear=${planYear}`,

  getDashboardCountryStat: (planYear) => `/api/dashboard/country/stat?planYear=${planYear}`,
  getDashboardZoneStat: (planYear) => `/api/dashboard/zone/stat?planYear=${planYear}`,
  getDashboardAverageRating: (planYear) =>
    `/api/dashboard/classroom-monitoring/average-rating?planYear=${planYear}`,
  getDashboardOverallRating: (planYear) =>
    `/api/dashboard/classroom-monitoring/overall-rating?planYear=${planYear}`,
  getDashboardIsasCategoryRating: (planYear) =>
    `/api/dashboard/classroom-monitoring/average-rating/isas-category?planYear=${planYear}`,
};
