export const ssoTokenCookie = 'TOKEN_COOKIE_KEY';

export const apiMethod = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE',
};

export const searchParamsConst = {
  token: 'token',
  username: 'username',
};

export const localizationNS = {
  common: 'common',
  visit: 'visit',
  notice: 'notice',
  cliche: 'cliche',
  monitoring: 'monitoring',
  report: 'report',
  settings: 'settings',
};

export const appLanguages = {
  English: 'en',
  Bengali: 'bn',
};

export const dateFormat = {
  YYYYMMDD: 'YYYYMMDD', // 2024-01-31
  DDMMYYYY: 'DDMMYYYY', // 31-01-2024
  DDMMMYYYY: 'DDMMMYYYY', // 03 March, 2024
  MMM: 'MMM', // February
  MMMYY: 'MMMYY',
  MMMYYYY: 'MMMYYYY',
};

export const colorVariant = {
  primary: 'primary',
  secondary: 'secondary',
  success: 'success',
  danger: 'danger',
  warning: 'warning',
  info: 'Info',
  light: 'light',
  dark: 'dark',
};

export const toastPosition = {
  topStart: 'top-start',
  topCenter: 'top-center',
  topEnd: 'top-end',
  middleStart: 'middle-start',
  middleCenter: 'middle-center',
  middleEnd: 'middle-end',
  bottomStart: 'bottom-start',
  bottomCenter: 'bottom-center',
  bottomEnd: 'bottom-end',
};

export const modalWidth = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
};

export const planView = {
  calendar: 'calendarView',
  list: 'listView',
  institute: 'instituteView',
};

export const planCalendarStatus = {
  draft: 'DRAFT',
  submitted: 'SUBMITTED',
  rejected: 'REJECTED',
};

export const MIMETypes = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  PDF: 'application/pdf',
};

export const REPORT_TYPE = {
  PDF: 'PDF',
  EXCEL: 'EXCEL',
  CSV: 'CSV',
};

export const BREAK_POINT = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const NOTIFICATION_MEDIUM = {
  sms: 'SMS',
  email: 'EMAIL',
  push: 'PUSH',
};

export const NOTIFICATION_TYPE = {
  regular: 'REGULAR',
  notice: 'NOTICE',
};

export const REDIRECT_AFTER_TYPE = {
  success: 'success',
  error: 'error',
  delete: 'delete',
  notFound: 'notFound',
};

export const LOCATION_TYPE = {
  upazila: 'UPAZILA',
  district: 'DISTRICT',
  zone: 'ZONE',
  national: 'NATIONAL',
};

export const PREVIOUS_PAGE = 'back';

export const UPLOAD_PATH = '/uploads';

export const monitoringValueType = {
  number: 'NUMBER',
  options: 'OPTION_ID',
  option: 'OPTION',
  boolean: 'BOOLEAN',
};

export const renderValueType = {
  computedCheckbox: 'COMPUTED_CHECKBOX_LIST',
};

// export const menuItems = {
//   dashboard: 'dashboard',
//   planSessionSettings: 'plan-session-settings',
//   visitPlan: {
//     name: 'visit-plan',
//     myPlan: 'my-plan',
//     yearlyPlanSubmissionStats: 'yearly-plan-submission-stats',
//     yearlyPlanSubmissionISASWise: 'yearly-plan-submission-isas-wise',
//     visitReport: 'visit-report',
//   },
//   holiday: 'holiday',
//   monitorings: {
//     name: 'monitorings',
//     classroomMonitoring: 'classroom-monitoring',
//     institutionalMonitoring: 'institutional-monitoring',
//     officeMonitoring: 'office-monitoring',
//   },
//   instituteList: 'institute-list',
//   officeList: 'office-list',
//   monitoringBI: 'monitoring-bi',
//   report: {
//     name: 'report',
//     institution: 'institution',
//     office: 'office',
//     curriculum: 'curriculum',
//     others: 'others',
//   },
//   surveyDataCollection: 'survey-data-collection',
//   noticeManagement: 'notice-management',
//   noticeList: 'notice-list',
//   auditLog: 'audit-log',
//   settings: 'settings',
//   helpCenter: 'help-center',
// };

export const PERMISSIONS = {
  MANAGE_PLAN_SESSION: 'MANAGE_PLAN_SESSION',
  SUBMIT_VISIT_PLAN: 'SUBMIT_VISIT_PLAN',
  SUPERVISE_SUBMITTED_PLAN: 'SUPERVISE_SUBMITTED_PLAN',

  VISIT_INSTITUTE: 'VISIT_INSTITUTE',
  VISIT_OFFICE: 'VISIT_OFFICE',
  MANAGE_SUBMITTED_VISIT: 'MANAGE_SUBMITTED_VISIT',

  VIEW_OFFICER_DETAIL: 'VIEW_OFFICER_DETAIL',
  VIEW_INSTITUTE_DETAIL: 'VIEW_INSTITUTE_DETAIL',

  MANAGE_HOLIDAY: 'MANAGE_HOLIDAY',
  MANAGE_AUDIT: 'MANAGE_AUDIT',
  CONTACT_MODULE: 'CONTACT_MODULE',
  EXTRA_JURISDICTION: 'EXTRA_JURISDICTION',
  MANAGE_NOTICE: 'MANAGE_NOTICE',
  VIEW_BI: 'VIEW_BI',
  VIEW_REPORT: 'VIEW_REPORT',

  MANAGE_SYSTEM_SETTINGS: 'MANAGE_SYSTEM_SETTINGS',
};

export const SURVEY_FORM_TYPE = {
  GENERAL_MONITORING: 'GENERAL_MONITORING',
  CLASSROOM_MONITORING: 'CLASSROOM_MONITORING',
  OFFICE_MONITORING: 'OFFICE',
};

export const BOOLEAN_TEXT = {
  yesBn: 'হ্যাঁ',
  yesEn: 'Yes',
  noBN: 'না',
  noEN: 'No',
};
