import React from 'react';
import { MenuItem } from './layoutUtils';
import { Accordion } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { en2BnConversion } from '../../utils/helperFunction';
import { useSelector } from 'react-redux';
import { generalReducer } from '../../redux/generalSlice';

interface MenuComponentProps {
  menuItems: MenuItem[];
}

const MenuComponent: React.FC<MenuComponentProps> = ({ menuItems }) => {
  const navigate = useNavigate();
  const { appLanguage, activeNavItem } = useSelector(generalReducer);

  const handleSelect = (key: string | null, navigateTo: string | null) => {
    if (navigateTo) {
      navigate(navigateTo);
    }
  };

  const renderMenu = (items: MenuItem[]) => {
    return items.map((item) => {
      if (item.isCollapsible && item.children.length) {
        return (
          <Accordion key={item.key} className='px-2'>
            <Accordion.Item eventKey={item.key}>
              <Accordion.Header className='pb-1'>
                <div className='d-flex align-items-center gap-3 '>
                  {item.icon && (
                    <div>
                      <img src={item.icon} className='leftbar_icon_size' />
                    </div>
                  )}
                  <div>{en2BnConversion(item.labelBn, item.labelEn, appLanguage)}</div>
                </div>
              </Accordion.Header>
              <Accordion.Body className='py-1 px-32p '>{renderMenu(item.children)}</Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
      } else if (!item.isCollapsible) {
        return (
          <div key={item.key}>
            <div
              className={`px-2 py-1 pointer ${activeNavItem === item.key ? 'active' : ''}`}
              onClick={() => handleSelect(item.key as string, item.navigate)}
            >
              <div className='d-flex align-items-center gap-3 '>
                {item.icon && (
                  <div>
                    <img src={item.icon} className='leftbar_icon_size' />
                  </div>
                )}
                <div>{en2BnConversion(item.labelBn, item.labelEn, appLanguage)}</div>
              </div>
            </div>
          </div>
        );
      }
      return null;
    });
  };

  return <>{renderMenu(menuItems)}</>;
};

export default MenuComponent;
