import React from 'react';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dms_logo from '../../../src/assets/icons/gov_bd_logo.svg';
import profile_picture from '../../../src/assets/images/profile_picture.png';
import bell_icon from '../../../src/assets/icons/bell_icon.svg';
import { authInfo } from '../../pages/auth/authSlice';
import { appLanguages } from '../../constants/appConstants';
import { logout } from '../../services/logoutService';
import '../index.css';
import { appRoutes } from '../../constants/appRoutes';
import dropdown_icon_white from '../../../src/assets/icons/dropdown_icon_white.svg';

const PublicTopbar = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const {
    isLoggedIn,
    user: { fullNameBangla, fullNameEnglish, designationBangla, designationEnglish },
  } = useSelector(authInfo);

  const onLoginButton = () => {
    if (!isLoggedIn) {
      navigate(appRoutes.redirectToSSO);
      // window.location.href = `${process.env.REACT_APP_API_URL}${apiRoutes.ssoLogin}`;
    }
  };

  return (
    <div className='dms-primary-color sticky-top'>
      <div className='topbar-container'>
        {/* logo */}
        <div
          className='fs-2 fw-bold d-flex align-items-center  gap-2 pointer'
          onClick={() => navigate(appRoutes.dashboard)}
        >
          <img src={dms_logo} className='img-fluid' alt='DMS logo' />
          <span className='logo-text'>DMS</span>
          <span className='logo-small-text'>
            Digital Monitoring <br /> System
          </span>
        </div>
        {/* profile details */}
        <div className='d-flex align-items-center gap-3'>
          {isLoggedIn ? (
            <>
              <img src={bell_icon} className='nav-bell-icon img-fluid ' alt='Profile picture' />
              <img
                src={profile_picture}
                className='nav-profile-img rounded-circle img-fluid '
                alt='Profile picture'
              />
              <div className=' d-flex flex-column '>
                <span className='name-text'>
                  {i18n.language === appLanguages.Bengali ? fullNameBangla : fullNameEnglish}
                </span>
                <span className='designation-text'>
                  {i18n.language === appLanguages.Bengali ? designationBangla : designationEnglish}
                </span>
              </div>
              <Dropdown style={{ backgroundColor: '#0061F0', color: 'white' }}>
                <Dropdown.Toggle
                  style={{ backgroundColor: '#0061F0', color: 'transparent', borderStyle: 'none' }}
                >
                  {' '}
                  <img src={dropdown_icon_white} className=' img-fluid ' alt='Profile picture' />
                </Dropdown.Toggle>

                <Dropdown.Menu className='mt-4'>
                  <Dropdown.Item href='#'>Profile</Dropdown.Item>
                  <Dropdown.Item onClick={() => dispatch(logout())}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            <>
              <Button onClick={onLoginButton}>{t('login')}</Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PublicTopbar;
